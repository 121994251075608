// Font Family

@font-face {
  font-family: "Gotham Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Regular"),
    url("../fonts/Gotham-Book.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gotham Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Medium"),
    url("../fonts/Gotham-Medium.otf") format("opentype");
  font-display: swap;
}

$georgiaReg: "Gotham Regular", Serif;
$georgiabol: "Gotham Medium", Sans-Serif;

// Colors ---------------
// $primary-color: #0070bb;
$primary-color: #2484c4;
$divider: #f9f9f9;
$text-color: #777;
$heading-color: #222;
$main-bg: #707070b3;
$black: #000;
$white: #fff;
$borderColor: #ededed;
$light-gray: #f8f8f8;

$gradient_bg: linear-gradient(247deg, #9fd6f9 0%, #7db4dc 35%, #0070bb 100%);

// Responsive Variables
$xxl: "(min-width: 1600px)";
$xl: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$lg: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm: "(max-width: 767px)";
$lsm: "only screen and (min-width: 576px) and (max-width: 767px)";
$xsm: "(max-width: 575px)";
$tiny: "(max-width: 399px)";

// Font Family

$pfont: 16px;
$pmblfont: 15px;

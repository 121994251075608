/*=======  Location Filter  =======*/
.location-filter {
  .location-form-inner {
    box-shadow: 0px 3px 6px #00000029;
    background: $white;
    padding: 0px 0px 0px 30px;
    text-align: initial;
    border-radius: 70px;

    @media #{$sm,$md} {
      border-radius: 0px;
      padding: 30px;
    }
  }
  form {
    input,
    select {
      background-color: transparent;
      width: 100%;
      height: 50px;
      font-size: 16px;
      border: none;
      line-height: 50px;
      color: $black;
      &::placeholder {
        color: $black;
      }
    }

    .react-datepicker-wrapper {
      @media #{$sm,$md} {
        width: 100%;
      }
    }

    .inputs-filed {
      position: relative;
      select,
      input {
        padding: 0 20px;
      }

      .icon {
        position: absolute;
        left: 0;
        bottom: 13px;
        font-size: 16px;
        color: $primary-color;
      }
    }

    .guestdiv {
      border-bottom: 2px solid $primary-color;
    }
    .incbtns {
      background: transparent;
      border: none;
      outline: none;
      color: $primary-color;
    }
    .guestInput {
      text-align: center;
      line-height: 0;
      height: auto;
    }
    .rightArrow {
      @media #{$sm,$md} {
        display: none;
      }
    }
    .searchBtn {
      width: 100%;
      background: $primary-color;
      border: 2px solid $primary-color;
      border-top-right-radius: 70px;
      border-bottom-right-radius: 70px;
      font-size: 18px;
      font-weight: 400;
      color: $white;
      text-transform: capitalize;
      height: 60px;
      letter-spacing: 0px;
      @include transition(0.3s);
      &:hover {
        background: $white;
        color: $black;
      }
      @media #{$sm} {
        border-radius: 0px;
        margin-top: 2rem;
        width: 50%;
      }
      @media #{$md} {
        border-radius: 0px;
        margin-top: 2rem;
        width: 30%;
      }
      @media #{$lg} {
        font-size: 16px;
      }
    }
  }
}

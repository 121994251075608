.topheader-section {
  padding: 1rem 2rem 0rem 2rem;
  @media #{$sm,$md} {
    display: none;
  }
  a {
    color: $black;
    margin-right: 0.7rem;
    &:hover {
      color: $primary-color;
    }
  }
  .phone {
    margin-left: 1rem;
    &:hover {
      color: $primary-color;
    }
  }
  span {
    margin-right: 1rem;
  }
}

.bottom-header {
  padding: 1rem;
  display: none;
  background: $borderColor;
  @media (max-width: 520px) {
    display: block;
  }
  .userLogin {
    font-size: 23px;
    margin-right: 1.5rem;
    color: $black;
    position: relative;
    @media #{$sm} {
      font-size: 20px;
      margin-right: 1rem;
    }
    .DialogWindow {
      height: 8rem;
    }
    .submenu {
      position: absolute;
      left: 0;
      top: 110%;
      width: auto;
      background-color: #fff;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-out 0s;
      z-index: 99;
      // height: 6rem;
      overflow: auto;

      li {
        display: block;
        a {
          display: block;
          padding: 10px;
          position: relative;
          transition: all 0.3s ease-out 0s;
          border-radius: 0;
          margin: 0 0;
          border-bottom: 1px solid rgba(242, 244, 248, 0.7);
          color: $text-color;
          font-size: 15px;

          &.active,
          &:hover {
            background-color: $primary-color;
            color: $white !important;
            border-color: $primary-color;
          }
        }
      }
    }

    &:hover {
      & > .submenu {
        opacity: 1;
        visibility: visible;
        top: 100%;
      }
    }
  }
  .heartIcon {
    font-size: 23px;
    margin-right: 1.5rem;
    color: $black;
    position: relative;
    @media #{$sm} {
      font-size: 20px;
      margin-right: 1rem;
    }

    .item-count {
      // display: inline-block;
      position: absolute;
      left: 15px;
      top: 0;
      font-size: 11px;
      background: $primary-color;
      color: $white;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      letter-spacing: 0;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .header-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.5rem 1rem;
    font-size: 16px;
    border-radius: 24px;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    letter-spacing: 0px;
    color: #000;
    border: 2px solid #0070bb;

    &:hover {
      background-color: #0070bb;
      color: #fff;
      -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
      box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
    }
  }
}

.error-wrapper {
  $anims: float, floatReverse, float2, floatReverse2;
  $easeSmooth: cubic-bezier(0.39, 0.575, 0.28, 0.995);
  $color: $primary-color;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: $white;
  overflow: hidden;
  .particle {
    position: absolute;
    display: block;
    pointer-events: none;

    @for $i from 1 through 80 {
      &:nth-child(#{$i}) {
        $size: random(20) + 10;
        $blur: $i * 0.02;
        $speed: random(20) + 20;
        $delay: random(10) * 0.1;
        $anim: nth($anims, random(length($anims)));

        top: calc(random(100) / (100 + $size/8)) * 100%;
        left: calc(random(100) / (100 + $size/10)) * 100%;
        font-size: $size + px;
        filter: blur((#{$blur}) + px);
        animation: $speed + s $anim infinite;
      }
    }
  }

  @keyframes apparition {
    from {
      opacity: 0;
      transform: translateY(100px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes float {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(180px);
    }
  }

  @keyframes floatReverse {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-180px);
    }
  }

  @keyframes float2 {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(28px);
    }
  }

  @keyframes floatReverse2 {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-28px);
    }
  }

  @mixin animation($delay, $duration, $animation) {
    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
  .img-wrapper {
    height: 50vh;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .txt-wrapper {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 10px #eee;
    background-color: $white;
    padding: 2rem 1rem;
    z-index: 99;
    // animation: apparition 0.8s 1.2s $easeSmooth forwards;
    .status-code {
      font-weight: 700;
      font-family: itc-avant-garde-gothic-pro, sans-serif;
      font-size: 5.2rem;
      line-height: 57px;
      color: $primary-color;
    }
    .subtext {
      font-size: 3rem;
      line-height: 46px;
      font-family: sans-serif;
      font-weight: 200;
      color: $primary-color;
    }
    .description {
      font-size: 1.2rem;
      line-height: 26px;
      color: $black;
      font-family: sans-serif;
      font-weight: 200;
      width: 75%;
      text-align: center;
    }
    button {
      width: 200px;
      margin-top: 1rem;
      padding: 0.5rem;
      color: $white;
      background: $primary-color;
      border: 1px solid $primary-color;
      &:hover {
        background: $white;
        color: $black;
      }
    }
  }
}

#ourTeam {
  .mobileVIew {
    display: none;
    @media #{$sm} {
      display: block;
    }
  }

  .ourTeamCards {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media #{$sm} {
      display: none;
    }
  }
  .cardContainer {
    position: relative;
    margin-top: 2rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 20px;
    flex-wrap: wrap;
    .card {
      overflow: hidden;
      border: none;
      margin: 10px 10px;
      border-radius: 5px;
      position: relative;
      background-color: transparent;
      float: left;
      box-sizing: border-box;
      min-height: 400px;

      .imgBx {
        background-color: rgba(0, 0, 0, 1);
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        img {
          // min-width: 320px;
          width: 100%;
          min-height: 400px;
          transition: 0.5s;
          object-fit: cover;
          opacity: 0.75;
        }
      }
      &:hover .imgBx img {
        transform: scale(1.1);
        opacity: 0.5;
      }
      &:hover .cardFooter {
        h4 {
          transition: 0.2s;
          margin-bottom: -2px;
        }
        p {
          transition: 0.2s;
          margin-bottom: -7px;
        }
        .footerDetails {
          margin-bottom: 0;
          transition: 0.3s;
        }
        .social {
          bottom: 0;
          transition: 0.3s;
          ul {
            li {
              bottom: 1rem;
              transition: 0.1s;
            }
            li:nth-child(1) {
              transition-delay: 200ms;
            }
            li:nth-child(2) {
              transition-delay: 250ms;
            }
            li:nth-child(3) {
              transition-delay: 300ms;
            }
            li:nth-child(4) {
              transition-delay: 350ms;
            }
            li:nth-child(5) {
              transition-delay: 400ms;
            }
            li:nth-child(6) {
              transition-delay: 450ms;
            }
            li:nth-child(7) {
              transition-delay: 500ms;
            }
          }
        }
      }
      .cardFooter {
        color: white;
        position: absolute;
        width: 90%;
        bottom: 0;
        display: flex;
        flex-direction: column;
        padding: 10px 10px;
        p {
          transition: 0.2s;
          margin-top: 2px;
        }
        h4 {
          color: #fff;
          transition: 0.2s;
          font-size: 20px;
        }
        .footerDetails {
          margin-bottom: -3.5rem;
          transition: 0.3s;
        }
      }
      .social {
        position: relative;
        bottom: -5rem;
        overflow: hidden;
        transition: 0.3s;
        .ReadMoreStyle {
          font-weight: 600;
          font-size: 14px;
          width: max-content;
          margin-top: 5px;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            transition: 0.3s;
            text-decoration: underline;
          }
        }
        ul {
          list-style: none;
          display: flex;
          justify-content: flex-end;
          gap: 20px;
          li {
            cursor: pointer;
            position: relative;
            bottom: -1rem;
            transition: 0.1s;
            a {
              text-decoration: none;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.popDIalog_heading {
  color: #000;
  font-size: 18px;
}
.popDIalog_paragraph p {
  color: #000;
  font-size: 15px;
}

.Toastify__toast-container--top-center {
  width: auto;
  p {
    font-size: 15px;
  }
}

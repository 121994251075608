/*=======  Locations   =======*/
.locations-list-loop {
  .locations-box {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50px;
    overflow: hidden;

    .locations-img-wrap {
      overflow: hidden;
      width: 100%;
      height: 100%;
      min-height: 310px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;

      @media #{$md} {
        min-height: 350px;
        border-radius: 0;
      }

      @media #{$sm} {
        border-radius: 0;
      }

      @media #{$xsm} {
        min-height: 250px;
        border-radius: 0;
      }

      .locations-img {
        height: 100%;
        width: 100%;
        @include transition(0.4s);
        background-size: cover;
        background-position: center;
      }
    }

    &:hover {
      .locations-img {
        transform: scale(1.1);
      }
    }

    .locations-desc {
      background-color: $white;
      font-size: 14px;
      padding: 30px 50px;
      position: relative;

      @media #{$md} {
        padding: 30px 40px;
      }
      @media #{$sm} {
        padding: 30px;
      }

      .icons {
        background-color: $white;
        padding: 15px;
        box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
        display: inline-block;
        margin-bottom: 25px;

        li {
          display: inline-block;
          margin: 0px 10px;
          color: $black;

          @media #{$lsm} {
            margin: 0 8px;
          }

          @media #{$xsm} {
            margin: 0 5px;
          }
        }
      }
      .title {
        font-size: 24px;
        letter-spacing: 0px;
        margin-bottom: 15px;
        a {
          color: $primary-color;
        }
        @media #{$sm} {
          font-size: 22px;
        }
      }
      .studio {
        font-size: 16px;
        color: $primary-color;
        margin-bottom: 10px;
      }
      .sqfeet {
        hr {
          border-bottom: 1px solid $main-bg;
          margin-top: 07px;
          width: 30%;
        }
      }
    }
  }
}

/*=======  Locations Details  =======*/
.locations-details {
  .locationdetail_img {
    border-radius: 20px;
    @media #{$sm} {
      border-radius: 10px;
    }
  }
  .location-text {
    .title {
      font-size: 30px;
      letter-spacing: 0px;
      color: $primary-color;
      text-align: center;
      @media #{$lsm} {
        font-size: 25px;
      }
      @media #{$xsm} {
        font-size: 20px;
      }
    }
    .subtitle {
      font-size: 18px;
      text-align: center;
      font-weight: 600;
    }
    .detail {
      font-size: $pfont;
      text-align: center;
      color: $main-bg;
      p {
        @media #{$sm, $md} {
          text-align: center;
        }
      }
    }
  }
}

/*=======  TEXT BLOCK  =======*/
.text-block {
  &.with-bg {
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: "";
      background-color: $white;
      z-index: -1;
      opacity: 0.94;
    }
  }
  .invest-img {
    border-radius: 20px;
  }
  .block-text {
    p {
      @media #{$xsm} {
        padding-right: 0;
      }
    }
  }
  .mb-small {
    @media #{$md} {
      margin-bottom: 50px;
    }
    @media #{$sm} {
      margin-bottom: 50px;
    }
  }

  figure {
    overflow: hidden;
    height: 400px;
    border-radius: 20px;
    margin: 0;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      @include transition(0.3s);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}

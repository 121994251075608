/*=======  About CSS  =======*/
.about-list-property {
  p {
    font-size: $pfont;
    margin-bottom: 20px;
    text-align: justify;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

/*=======  Property Calculator CSS  =======*/

.property-calculator {
  .calculator-form {
    .input-group {
      select {
        background: $white;
        background: #0070bb98;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 24px;
        color: $white;
        height: 50px;
        padding: 0 20px;
      }

      .selectcustomarrowstyle {
        /* Arrow */
        appearance: none;
        background-image: url("../arrowdown.png");
        background-repeat: no-repeat;
        background-position: right 0.7rem top 50%;
        background-size: 1.2rem auto;
      }
    }

    .main-btn.btn-filled {
      box-shadow: none;
      padding-left: 80px;
      padding-right: 80px;
      font-size: 18px;
      letter-spacing: 0px;
      border-radius: 0;
    }
  }
  .property-summary {
    // text-align: center;
    padding: 30px;
    // color: $white;
    // background: linear-gradient(180deg, #0070bb 0%, #63d8dc 100%);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;

    @media #{$sm} {
      padding: 20px;
    }

    h2 {
      // color: $white;
      font-size: 25px;
      margin-bottom: 1rem;
      span {
        color: $primary-color;
      }
    }
    p {
      margin-bottom: 1rem;
    }

    .price {
      font-size: 25px;
      font-weight: 600;
      // margin-bottom: 2rem;
    }
    .desc {
      font-size: 14px;
    }
  }
}

/*-----Loader Css-------*/
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 10px auto;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-----Loader Css-------*/

/*-----second Loader Css-------*/
.loader_2,
.loader_2:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader_2 {
  margin: 10px 0;
  font-size: 9px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.6em solid rgba(255, 255, 255, 0.2);
  border-right: 0.6em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.6em solid rgba(255, 255, 255, 0.2);
  border-left: 0.6em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-----second Loader Css-------*/
/*======= Connect us form CSS  =======*/
.connect-us-area {
  background: $main-bg;
  .connect-us-form {
    .input-group {
      input,
      textarea {
        background: $white;
        padding: 10px 20px;
        border: 1px solid $main-bg;
        box-shadow: 0px 3px 6px #00000029;
      }
    }
    textarea {
      height: 180px;
    }

    .main-btn.btn-filled {
      box-shadow: none;
      padding-left: 80px;
      padding-right: 80px;
      font-size: 18px;
      letter-spacing: 0px;
    }
  }
}

/*=======  Faq CSS  =======*/

.faqs-area {
  .card {
    border: none;
    margin-bottom: 1.5rem;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50px !important;
  }
  .card-header {
    background: $white;
    margin-bottom: 0 !important;
    font-size: $pfont;
    border-bottom: 0;
    @media #{$sm} {
      font-size: $pmblfont;
    }
  }
  .card-body {
    font-size: $pfont;
    padding-top: 0;
    color: $main-bg;
    hr {
      margin-top: 0;
      border-color: $main-bg;
    }
    p {
      font-size: $pfont;
      @media #{$sm} {
        font-size: $pmblfont;
      }
    }
  }
}

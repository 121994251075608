/*=======  property Availability Search Filter  =======*/
.property_availability_search {
  box-shadow: 0px 3px 6px #00000029;
  background: $white;
  padding: 20px;
  text-align: initial;
  margin-top: 50px;

  form {
    input,
    select {
      background-color: transparent;
      width: 100%;
      height: 50px;
      font-size: 14px;
      border: none;
      border-bottom: 2px solid $primary-color;
      line-height: 50px;
      color: $black;
      &::placeholder {
        color: $black;
      }
    }
    .colBottomSpace {
      padding-bottom: 0.3rem;
    }
    .react-datepicker-wrapper {
      width: 100%;
    }

    .inputs-filed {
      position: relative;
      select,
      input {
        padding: 0 20px;
      }

      .icon {
        position: absolute;
        left: 0;
        bottom: 13px;
        font-size: 14px;
        color: $primary-color;
      }
    }

    button {
      background: $primary-color;
      border: 2px solid $primary-color;
      border-radius: 0px;
      font-size: 18px;
      font-weight: 400;
      color: $white;
      text-transform: capitalize;
      height: 60px;
      letter-spacing: 0px;
      // margin-top: 2rem;
      width: 100%;
      @include transition(0.3s);
      &:hover {
        background: $white;
        color: $black;
      }
      @media #{$sm, $md} {
        margin-top: 1rem;
      }
    }
  }
}

/*=======  ABOUT CSS  =======*/
.about-section {
  position: relative;
  overflow-x: hidden;
  z-index: 1;
  .about-detail {
    text-align: left;
    font-weight: 500;
    font-size: $pfont;
    @media #{$sm} {
      font-size: $pmblfont;
    }
    p {
      text-align: left;
    }
  }
  .down-arrow-wrap {
    padding-bottom: 125px;
    @media #{$md} {
      padding-bottom: 90px;
    }
    @media #{$sm} {
      padding-bottom: 90px;
    }
    a.down-arrow {
      height: 60px;
      width: 60px;
      text-align: center;
      line-height: 60px;
      background: $primary-color;
      border-radius: 50%;
      color: $white;
      box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
      position: relative;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border: 1px solid #cccccc;
        border-radius: 50%;
        animation: pulse-border-2 1s linear infinite;
      }
      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border: 1px solid #cccccc;
        border-radius: 50%;
        animation: pulse-border-2 1.5s linear infinite;
      }
    }
  }

  .about-title {
    span.title-tag {
      span {
        color: $primary-color;
      }
    }
    h2 {
      @media #{$md} {
        font-size: 45px;
      }
      @media #{$xsm} {
        font-size: 30px;
      }
    }
  }

  .abour-text {
    @media #{$md} {
      padding: 0;
      margin-top: 50px;
    }
    @media #{$sm} {
      padding: 0;
      margin-top: 50px;
    }
  }

  .about-text-box {
    background-color: $white;
    // padding: 30px;
    // margin-top: 50px;
    // @media #{$md} {
    //   padding: 50px;
    // }
    // @media #{$lsm} {
    //   padding: 50px;
    // }
    // @media #{$xsm} {
    //   padding: 40px 20px;
    // }

    .about-img {
      border-radius: 20px;
    }
    .about-text {
      font-size: 14px;
      padding-left: 20px;
      @media #{$md} {
        padding: 0;
        margin-top: 50px;
      }
      @media #{$sm} {
        padding: 0;
        margin-top: 50px;
      }
      i {
        font-size: 20px;
        color: $primary-color;
      }
      .authordes {
        text-align: justify;
        font-size: $pfont;
        @media #{$sm} {
          font-size: $pmblfont;
        }
      }
      .social_media {
        margin-top: 1rem;
        h3 {
          font-size: 24px;
          margin-bottom: 0.5rem;
          span {
            color: $primary-color;
          }
        }
        .social_link_wrape {
          display: flex;
          .link_wrap {
            margin-right: 0.7rem;
            &:last-child {
              margin-right: 0;
            }
            height: 40px;
            width: 40px;
            color: $primary-color;
            background: $white;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              color: $primary-color;
            }
          }
        }
      }
      .authorname {
        text-align: right;
        margin-top: 2rem;
        font-size: 20px;
      }
      .authordesig {
        text-align: right;
        font-size: 16px;
        color: $primary-color;
      }
      span {
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      h3 {
        font-size: 48px;
        margin-bottom: 30px;
        letter-spacing: 0px;
        @media #{$lg} {
          font-size: 38px;
        }
        @media #{$lsm} {
          font-size: 34px;
        }
        @media #{$xsm} {
          font-size: 32px;
        }
        span {
          color: $primary-color;
          text-transform: capitalize;
        }
      }
      .main-btn {
        margin-top: 30px;

        @media #{$xsm} {
          padding: 0 35px;
        }
      }
    }
  }
}

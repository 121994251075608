/*=======  Service  =======*/
.service-area {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50px;
  background: $white;
  padding: 60px;
  margin-top: -75px;
  z-index: 2;
  position: relative;
  @media #{$md} {
    padding: 40px;
  }
  @media #{$lsm} {
    padding: 30px;
  }
  @media #{$xsm} {
    padding: 20px;
  }
  @media #{$tiny} {
    padding: 15px;
  }
  .section-title h2 {
    color: $main-bg;
    @media #{$sm} {
      padding-top: 30px;
    }
  }
  .service-boxes-loop {
    .service-box {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0px;
      }
      .service-desc {
        img {
          border-radius: 20px;
          @include transition(0.3s);
          &:hover {
            transform: scale(1.1);
          }
        }
        .main-btn {
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 30px;
          letter-spacing: 0;
          margin-top: 40px;
          @media #{$xsm} {
            padding: 0 35px;
            line-height: 40px;
          }
        }
        .service-text {
          .title {
            font-size: 28px;
            letter-spacing: 0px;
            @media #{$sm} {
              font-size: 25px;
              margin-bottom: 1rem;
            }
          }
          p {
            font-size: $pfont;
            @media #{$sm} {
              font-size: $pmblfont;
            }
            ul {
              margin-top: 1rem;
              @media #{$sm} {
                font-size: $pmblfont;
              }
            }
          }
        }
      }
    }
  }
}

/*=======  Gallery  =======*/
.gallery-loop {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  @media #{$sm} {
    grid-gap: 10px;
  }

  @media #{$xsm} {
    grid-gap: 10px;
  }

  .single-gallery-image {
    overflow: hidden;
    img {
      @include transition(0.4s);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .single-gallery-image:nth-child(3),
  .single-gallery-image:nth-child(6),
  .single-gallery-image:nth-child(9),
  .single-gallery-image:nth-child(12) {
    grid-column: 1 / 3;
  }

  .single-gallery-image:nth-child(3) {
    grid-row: 2 / 3;
  }

  .single-gallery-image:nth-child(6) {
    grid-row: 4 / 5;
  }

  .single-gallery-image:nth-child(9) {
    grid-row: 6 / 7;
  }

  .single-gallery-image:nth-child(12) {
    grid-row: 8 / 9;
  }
}

/*=======  Search Filter  =======*/
.search-filter {
  .search-form-inner {
    box-shadow: 0px 3px 6px #00000029;
    background: $white;
    padding: 0px 0px 0px 30px;
    text-align: initial;
    border-radius: 70px;

    @media #{$sm,$md} {
      border-radius: 0px;
      padding: 30px;
    }
  }
  form {
    input,
    select {
      -webkit-appearance: none;
      background-color: transparent;
      width: 100%;
      height: 50px;
      font-size: 14px;
      border: none;
      // border-bottom: 2px solid $primary-color;
      line-height: 50px;
      color: $black;
      &::placeholder {
        color: $black;
      }
    }
    .inputDateFields:hover .inputDateFieldsChild {
      outline: 1px solid $primary-color;
    }
    @media #{$xsm,$tiny } {
      .inputDateFieldsChild {
        outline: 1px solid $primary-color;
      }
    }
    .colBottomSpace {
      padding-bottom: 0.3rem;
    }
    .react-datepicker-wrapper {
      @media #{$sm,$md} {
        width: 100%;
      }
    }

    .rightArrow {
      @media #{$sm,$md} {
        display: none;
      }
    }

    .inputs-filed {
      position: relative;
      select,
      input {
        padding: 0 20px;
      }

      .icon {
        position: absolute;
        left: 0;
        bottom: 13px;
        font-size: 14px;
        color: $primary-color;
      }
    }

    button {
      width: 100%;
      background: $primary-color;
      border: 2px solid $primary-color;
      border-top-right-radius: 70px;
      border-bottom-right-radius: 70px;
      font-size: 18px;
      font-weight: 400;
      color: $white;
      text-transform: capitalize;
      height: 60px;
      letter-spacing: 0px;
      @include transition(0.3s);
      &:hover {
        background: $white;
        color: $black;
      }
      @media #{$sm,$md} {
        border-radius: 0px;
        margin-top: 2rem;
        width: 50%;
      }
      @media #{$md, $sm, $xsm, $tiny} {
        border-radius: 15px;
      }
      @media #{$lg} {
        font-size: 16px;
      }
    }
  }
}

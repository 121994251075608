.our-partners {
  // padding-top: 40px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // @media #{$sm} {
  //   padding-top: 0px;
  // }
  // .partdiv {
  //   cursor: pointer;
  //   border-radius: 100%;
  //   background: $primary-color;
  //   font-size: 19px;
  //   padding: 1.1rem;
  //   border: 2px solid $white;
  //   margin: 0 0.5rem;
  //   width: 150px;
  //   height: 150px;
  //   display: flex;
  //   justify-content: space-around;
  //   line-height: 30px;
  //   flex-direction: column;
  //   align-items: center;
  // }
  h2 {
    font-size: 32px;
    margin-bottom: 1rem;
    text-align: center;
    span {
      color: $primary-color;
    }
  }
}

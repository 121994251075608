/*=======  Core Feature  =======*/
.features-loop {
  .feature-box {
    height: 100%;
    padding: 30px;
    background-color: $white;
    font-size: 14px;
    background-color: $white;
    position: relative;
    box-shadow: 0px 3px 6px #00000029;

    @media #{$lg} {
      padding: 30px;
    }
    @media #{$md} {
      padding: 30px;
    }
    @media #{$sm} {
      padding: 25px;
      margin-bottom: 2rem;
    }
    h3 {
      font-size: 24px;
      margin-bottom: 30px;
      @media #{$sm} {
        font-size: 22px;
      }
    }
    p {
      text-align: justify;
    }
    .count {
      font-size: 34px;
      color: $main-bg;
      display: flex;
      justify-content: flex-end;
    }
    &.with-hover-img {
      .hover-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        @include transition(0.3s);

        visibility: visible;
        opacity: 1;
      }
      &:hover {
        .hover-img {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    /*=======  Dark Style  =======*/
    &.dark-box {
      background-color: #292929;
      border: none;

      &,
      h3 a {
        color: $white;
      }

      .count {
        color: $black;
      }
    }
  }
}
